var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-light border p-3 pt-2 pb-2",
    staticStyle: {
      "left": "0px"
    },
    style: _vm.isVisible ? 'left: 0px' : 'left: -189px',
    attrs: {
      "id": "style-switcher"
    }
  }, [_c('h3', {
    staticClass: "title text-center"
  }, [_vm._v("Select Your Color")]), _c('ul', {
    staticClass: "pattern text-center mb-2"
  }, [_c('li', {
    staticClass: "list-inline-item"
  }, [_c('a', {
    staticClass: "default",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setTheme('default');
      }
    }
  })]), _c('li', {
    staticClass: "list-inline-item"
  }, [_c('a', {
    staticClass: "green",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setTheme('green');
      }
    }
  })]), _c('li', {
    staticClass: "list-inline-item"
  }, [_c('a', {
    staticClass: "purple",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setTheme('purple');
      }
    }
  })]), _c('li', {
    staticClass: "list-inline-item"
  }, [_c('a', {
    staticClass: "red",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setTheme('red');
      }
    }
  })]), _c('li', {
    staticClass: "list-inline-item"
  }, [_c('a', {
    staticClass: "skyblue",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setTheme('skyblue');
      }
    }
  })]), _c('li', {
    staticClass: "list-inline-item"
  }, [_c('a', {
    staticClass: "skobleoff",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setTheme('skobleoff');
      }
    }
  })]), _c('li', {
    staticClass: "list-inline-item"
  }, [_c('a', {
    staticClass: "cyan",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setTheme('cyan');
      }
    }
  })]), _c('li', {
    staticClass: "list-inline-item"
  }, [_c('a', {
    staticClass: "slateblue",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setTheme('slateblue');
      }
    }
  })])]), _c('h3', {
    staticClass: "title text-center pt-3 mb-0 border-top"
  }, [_vm._v("Theme Option")]), _c('div', {
    staticClass: "text-center"
  }, [_c('a', {
    staticClass: "btn btn-sm w-100 btn-primary rtl-version t-rtl-light mt-2",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setRtl();
      }
    }
  }, [_vm._v("RTL")]), _c('a', {
    staticClass: "btn btn-sm w-100 btn-primary ltr-version t-ltr-light mt-2",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setLtr();
      }
    }
  }, [_vm._v("LTR")]), _c('a', {
    staticClass: "btn btn-sm w-100 btn-primary dark-rtl-version t-rtl-dark mt-2",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.darkRtl();
      }
    }
  }, [_vm._v("RTL")]), _c('a', {
    staticClass: "btn btn-sm w-100 btn-primary dark-ltr-version t-ltr-dark mt-2",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.darkLtr();
      }
    }
  }, [_vm._v("LTR")]), _c('a', {
    staticClass: "btn btn-sm w-100 btn-dark dark-version t-dark mt-2",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setDark();
      }
    }
  }, [_vm._v("Dark")]), _c('a', {
    staticClass: "btn btn-sm w-100 btn-dark light-version t-light mt-2",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.setLight();
      }
    }
  }, [_vm._v("Light")])]), _c('div', {
    staticClass: "bottom"
  }, [_c('a', {
    staticClass: "settings bg-white shadow d-block",
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": _vm.ToogleMenu
    }
  }, [_c('i', {
    staticClass: "mdi mdi-cog ml-1 mdi-24px position-absolute mdi-spin text-primary"
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }